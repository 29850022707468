import Vue from 'vue'
import Vuex from 'vuex'
import patient from './modules/patient'
import task from './modules/task'
// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        patient,
        task
    },
    strict: debug,
    // plugins: debug ? [createLogger()] : []
})
