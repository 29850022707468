import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use({router})

//http util
import httpUtil from './common/httpUtil'
Vue.prototype.$httpUtil = httpUtil

//session util
import sess from './common/sess'
Vue.prototype.$sess = sess

//constant
import urlConstant from './common/urlConstant'
Vue.prototype.$urlConstant = urlConstant

import constant from './common/constant'
Vue.prototype.$constant = constant

//date format
import {formatDate} from "./common/utils/dateUtil";
Vue.filter('formatDate',function (time) {
    if (time) {
        return formatDate(time, 'yyyy-MM-dd');
    }
})
Vue.filter('formatDate2',function (time) {
    if (time) {
        return formatDate(time, 'MM-dd hh:mm');
    }
})
Vue.filter('formatDateTime',function (time) {
    if (time) {
        return formatDate(time, 'yyyy-MM-dd hh:mm:ss');
    }
})
Vue.filter('formatTime',function (time) {
    if (time) {
        return formatDate(time, 'hh:mm:ss');
    }
})

import StringUtil from "@/common/utils/StringUtil";
Vue.filter('hidePhone',function (phone) {
    return StringUtil.hidePhone(phone)
})

//copy
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.prototype.$getComponentSize = function () {
    return window.innerWidth < 480 ? 'mini' : null
}

import { GlobalEvent } from './globalEvent';
Vue.prototype.$globalEvent = GlobalEvent

import globalErrorHandler from "@/common/globalErrorHandler";
Vue.use(globalErrorHandler)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
