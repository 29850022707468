// initial state
const state = () => ({
    searchQuery:{
        status: 1,
        currentPage: 1,
        orderDirection: -1,
        orderProperty: 'surveyModifiedTime'
    }
})

// getters
const getters = {}

// actions
const actions = {
    // getAllProducts ({ commit }) {
    //     shop.getProducts(products => {
    //         commit('setProducts', products)
    //     })
    // }
}

// mutations
const mutations = {
    storeSearchQuery(state, searchQuery) {
        state.searchQuery = JSON.parse(JSON.stringify(searchQuery))
    },
    cleanSearchQuery(state) {
        state.searchQuery = {
            status: 1,
            currentPage: 1,
            orderDirection: -1,
            orderProperty: 'surveyModifiedTime'
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
