export default {
    cms: {
        siteUserListGet: 'cms/api/admin/site/user',
        siteUserCreatePost: 'cms/api/admin/site/user',
        siteUserDelete: 'cms/api/admin/site/user/',
        adminSiteGetPut: 'cms/api/admin/site/',
        patchPost: 'cms/api/admin/patch/',
        siteGet: 'cms/api/cms/site',
        siteUpdatePut: 'cms/api/cms/site', //更新当前站点信息
        siteUpdateThemePut: 'cms/api/cms/site/theme', //更新当前站点主题
        siteAllowCommentPost: 'cms/api/cms/site/allow-comment',
        siteAllowRecommendPost: 'cms/api/cms/site/allow-recommend',
        siteShowTabBarPost: 'cms/api/cms/site/show-menu',
        siteAllowChatPost: 'cms/api/cms/site/allow-chat',
        siteAllowInquiryPost: 'cms/api/cms/site/allow-inquiry',
        siteAllowRewardPost: 'cms/api/cms/site/allow-reward',
        siteSaveInquiryPost: 'cms/api/cms/site/save-inquiry',
        siteUploadWxQrcodePost: 'cms/api/cms/site/qrcode',
        articleListPost: 'cms/api/cms/article/list/',
        articleCreatePost: 'cms/api/cms/article',
        articlePutGetDel: 'cms/api/cms/article/',
        articleStatusPost: 'cms/api/cms/article/status/',
        articleAdPost: 'cms/api/cms/article/ad/',
        articleSloganPost: 'cms/api/cms/article/slogan/',
        articleCmtPost: 'cms/api/cms/article/cmt/',
        articleFilePost: 'cms/api/cms/article/upfile',
        previewPost: 'cms/api/cms/preview',
        tagsGet: 'cms/api/cms/article/tags',
        categoryListGet: 'cms/api/cms/category',
        categoryCreatePost: 'cms/api/cms/category',
        categoryPutGetDel: 'cms/api/cms/category/',
        categorySaveSortPost: 'cms/api/cms/category/save-sort',
        categoryStatusPost: 'cms/api/cms/category/status/',
        menuListGet: 'cms/api/cms/menu',
        menuListForSelectGet: 'cms/api/cms/menu/for-select',
        menuCreatePost: 'cms/api/cms/menu',
        menuPutGetDel: 'cms/api/cms/menu/',
        menuSaveSortPost: 'cms/api/cms/menu/save-sort',
        menuStatusPost: 'cms/api/cms/menu/status/',
        tagSortGetCreate: 'cms/api/cms/tag-sort',
        questionListPost: 'cms/api/cms/question/list/',
        questionPutGetDel: 'cms/api/cms/question/',
        questionStatusPost: 'cms/api/cms/question/status/',
        mpSettingGetPost: 'cms/api/admin/setting/mp',
        userByIdsGet: 'cms/api/public/user/',
        userByUsernamesPost: 'cms/api/public/user/usernames',
    },
    wechat: {},
    login: {
        pwdLoginPost: 'cms/api/cms/login/login',
        logoutDelete: 'cms/api/cms/login/logout',
        ticketLogin: 'cms/api/cms/login/consume-ticket'
    },
    live: {
        listPost: "live/api/live/list/",
        createPost: "live/api/live",
        putGetDel: "live/api/live/",
        joinPost: "live/api/live/join/",
        leavePost: "live/api/live/leave/",
        vodListPost: "live/api/vod/list/",
        vodCreatePost: "live/api/vod",
        vodPutGetDel: "live/api/vod/",
        vodFullGet: "live/api/public/vod/",
        vodUploadSignGet: "live/api/vod/upload-sign"
    },
    user: {
        listByPhoneGet: "cms/api/cms/user/phone/",
        changePwdPost: "cms/api/cms/user/change_pwd",
        listGet: "cms/api/site-admin/users/",
        changeRolePost: "cms/api/site-admin/users/change-role",
        deleteUserDelete: "cms/api/site-admin/users/",
        userGet: 'cms/api/site-admin/users/',
        operatorListGet: 'cms/api/site-admin/users/operators',
        operatorCreatePost: 'cms/api/site-admin/users/operators',
        operatorPut: 'cms/api/site-admin/users/operators/',
        currentUserGet: "cms/api/cms/user/current",
        currentUserUpdatePut: "cms/api/cms/user"
    },
    chat: {
        chatChannelGet: 'chat/api/chat/channel/current',
        chatChannelPut: 'chat/api/chat/channel/id/',
        kickedUserListPost: 'chat/api/chat/user/kick-list/',
        removeChannelFromUserPost: 'chat/api/chat/user/remove-from-channel'
    },
    auth: {
        roleList: 'authority/api/auth/role',
        customRoleList: 'authority/api/auth/custom-role',
        menuList: 'authority/api/auth/menu',
        resourceList: 'authority/api/auth/resource',
        menusOfRoleList: 'authority/api/auth/menu/role/',
        roleGetPut: 'authority/api/auth/role/',
        rolePost: 'authority/api/auth/role',
        menuPost: 'authority/api/auth/menu',
        menuGetPutDelete: 'authority/api/auth/menu/',
        currentMenuGet: 'authority/api/auth/current/menu',
        getQr: 'authority/api/qr',
        checkQr: 'authority/api/qr/check/'
    },
    mall: {
        createArticleReward: 'mall/api/cms/reward/article'
    },
    survey: {
        surveySelectSource: 'survey/select/site/{0}/key/{1}',
        surveyGet: 'survey/api/survey/',
        surveyGetPost: 'survey/api/survey/get-by-id',
        surveyDataPost: 'survey/api/survey-data',
        surveyDataManualPost: 'survey/api/survey-data/manual',
        surveyDataWithTaskPost: 'survey/api/survey-data/task',
        surveyDataGetPut: 'survey/api/survey-data/',
        surveyDataListByOwner: 'survey/api/survey-data/list/owner/{0}/page/{1}/size/{2}',
        surveyUpfile: 'survey/api/agreement-data/upfile',
        repeatTokenGet: 'survey/api/survey-data/repeat-token'
    },
    patient: {
        getPatient: 'patient/api/patient/f/site/', //当前用户关联的患者信息
        getPatientByDoctor: 'patient/api/patient/f/info/', //指定id的患者信息
        createPatient: 'patient/api/patient/f/site/', //患者创建患者
        createPatientByDoctor: 'patient/api/patient/f/create-by-doctor', //医生创建患者
        currentDoctor: 'patient/api/doctor/f/current', //获取当前登录者的医生信息
        patientConfig: 'patient/api/patient-config/f', //获取当前登录者关联的患者配置
        patientConfigSurveyCaseTree: 'patient/api/patient-config/f/case-tree', //获取当前医生患者管理配置中的病历案例树
        bpSave: 'patient/api/bp',
        bpList: 'patient/api/bp/list/page/{0}/size/{1}',
        verifyPatient: 'patient/api/patient/f/verify',
        taskList: 'patient/api/task/list/page/{0}/size/{1}',
    },
    nuohua: {
        areaList: 'nuohua/api/area',
        areaListForSelect: 'nuohua/api/area/for-select',
        areaCreate: 'nuohua/api/area',
        areaGetPutDel: 'nuohua/api/area/',
        nurseList: 'nuohua/api/nurse',
        nurseGetPutDel: 'nuohua/api/nurse/',
        nurseCreate: 'nuohua/api/nurse',
        patientInfo: 'nuohua/api/patient/info/',
        patientList: 'nuohua/api/patient/nh-list/page/{0}/size/{1}',
        defaultSurveyConfig: 'nuohua/api/patient/survey-config',
        defaultSurveyConfigList: 'nuohua/api/patient/survey-config-list',
        defaultSurveyList: 'nuohua/api/patient/survey-list', //获取survey的配置关联的具体survey信息
        reportList: 'nuohua/api/report/list/page/{0}/size/{1}',
        reportListForCurrentUser: 'nuohua/api/report/list/current-user/page/{0}/size/{1}',
        taskList: 'nuohua/api/task/nh-list/page/{0}/size/{1}',
        dotList: 'nuohua/api/patient/dots',
        updateXh: 'nuohua/api/patient/update-xh',
        statisticList: 'nuohua/api/statistic',
        statisticExcel: 'nuohua/api/statistic/excel',
        statisticAreaList: 'nuohua/api/statistic/area-list',
        statisticHospitalList: 'nuohua/api/statistic/hospital-list',
    },
    support: {
        addUserTracePost: 'cms-support/api/support/user_trace',
        appErrorPost: 'cms-support/api/support/app_error',
    },
    pageSize: 15,
    fileSizeLimit: 100,
    menuType: [
        {name: '默认', value: 0},
        {name: '关于', value: 1},
        {name: '交流群', value: 2},
        {name: '挂号问诊', value: 3},
        {name: '诊疗中心', value: 4}, //刘庆专用
        {name: '随访', value: 5}
    ],
    categoryType: [
        {name: '默认', value: 1},
        // { name: '学术', value: 2 },
        {name: '预告', value: 3},
        {name: '相册', value: 4},
        {name: 'rex', value: 5},
        {name: '默认2', value: 7},
        {name: '默认3', value: 8}
    ],
    categoryType2: [
        {name: '默认', value: 1},
        {name: '默认2', value: 2},
        {name: '默认3', value: 3},
        {name: '默认4', value: 4},
        {name: '默认5', value: 5},
        {name: '默认6', value: 6},
        {name: '默认7', value: 7},
        {name: '默认8', value: 8},
        {name: '默认9', value: 9},
        {name: '默认10', value: 10}
    ],
    categoryType3: [
        {name: '默认', value: 1},
        {name: '默认2', value: 2}
    ],
    tabBars: [
        {name: '全部菜单', value: 'all'},
        {name: '视频', value: 'video'},
        {name: '图文', value: 'document'}
    ],
    liveStatus: [
        {name: '已创建', value: 0},
        {name: '直播中', value: 1},
        {name: '已结束', value: 2}
    ],
    videoStatus: [
        {name: '已上架', value: 1},
        {name: '删除中(稍后手动刷新)', value: 2},
        {name: '已下架', value: 0}
    ],
    userRole: {
        customer: 'customer',
        doctor: 'doctor',
        assistant: 'assistant',
        chatroomAdmin: 'chatroom_admin'
    },
    markStatus: [
        {name: '未编辑', value: 0,color:'#C84646'},
        {name: '部分编辑', value: 1,color:'#E4CA21'},
        {name: '已完成', value: 2,color:'#36B68A'},
        {name: '全部', value: undefined,color:'transparent'}
    ]
}
