// 这个函数 网上  随处可见，我也是应用了别人的。
export function formatDate(date, fmt) {
  if (typeof date == "string") {
    //兼容safari
    // if (date.indexOf("+") > 0) {
    //   date = date.substring(0, date.indexOf("+"));
    // }
    date = new Date(date);
  }

  // var timezone = 8; //目标时区时间，东八区
  // var offset_GMT = date.getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  // var nowTime = date.getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
  // date = new Date(nowTime + timezone * 60 * 60 * 1000);

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

export function addSecond(date, second) {
  if (typeof date == "string") {
    date = new Date(date);
  }
  return new Date(date.getTime() + second * 1000);
}