import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/',
    routes: [{
            path: '/login',
            name: 'Login',
            component: () => import('../components/Login')
        },{
            path: '/ticket-login',
            name: 'TicketLogin',
            component: () => import('../components/TicketLogin')
        },{
            path: '/patient/area-list',
            name: 'AreaList',
            component: () => import('../components/patient/AreaList')
        },{
            path: '/patient/area-edit',
            name: 'AreaEdit',
            component: () => import('../components/patient/AreaEdit')
        },{
            path: '/patient/nurse-edit',
            name: 'NurseEdit',
            component: () => import('../components/patient/NurseEdit')
        },{
            path: '/patient/nurse-list',
            name: 'NurseList',
            component: () => import('../components/patient/NurseList')
        },{
            path: '/patient/patient-list',
            name: 'PatientList',
            component: () => import('../components/patient/PatientList')
        },{
            path: '/patient/patient-edit',
            name: 'PatientEdit',
            component: () => import('../components/patient/PatientEdit')
        },{
            path: '/patient/patient',
            name: 'Patient',
            component: () => import('../components/patient/Patient')
        },{
            path: '/patient/patient-list-manager',
            name: 'PatientListForManager',
            component: () => import('../components/patient/PatientListForManager')
        },{
            path: '/patient/task-list',
            name: 'TaskList',
            component: () => import('../components/patient/TaskList')
        },{
            path: '/patient/report-list',
            name: 'ReportList',
            component: () => import('../components/patient/ReportList')
        },{
            path: '/patient/statistic-list',
            name: 'StatisticList',
            component: () => import('../components/patient/StatisticList')
        },{
            path: '/survey/survey',
            name: 'Survey',
            component: () => import('../components/survey/Survey')
        },{
            path: '/survey/task-survey',
            name: 'TaskSurvey',
            component: () => import('../components/survey/TaskSurvey')
        },{
            path: '/mp-url-gen',
            name: 'MpUrlGen',
            component: () => import('../components/common/MpUrlGen')
        },
        // 默认配置
        {
            path: '/*',
            redirect: '/'
        }
    ]
})
