/**
 * Created by yaoandw on 2017/3/21.
 */

import httpCommon from './http-common';
import sess from './sess';

let loading

export default {
    getGetter(query) {
        return httpCommon.getHTTP().get(query, {headers: {'authorization': sess.get('accessToken')}})
    },
    get(query, callback, vm) {
        // var th = this;
        console.log('get Authorization from sess: ' + sess.get('accessToken'));
        // HTTP.defaults.headers.common['Authorization'] = sess.get('accessToken');
        this.showLoading(vm)
        const headers = {
            'authorization': sess.get('accessToken'),
            'sys': sess.getSystemInfoMd5()?sess.getSystemInfoMd5():'',
            'ytuid': sess.getYtUuid()
        }
        return httpCommon.getHTTP().get(query, {headers: headers}).then((response) => {
            console.info(this);
            return this.doSuccess(response, callback, vm);
        }).catch((error) => {
            this.doError(error, vm);
        });
    },
    post(query, params, callback, vm) {
        this.showLoading(vm)
        let headParams = {
            'authorization': sess.get('accessToken'),
            'sys': sess.getSystemInfoMd5()?sess.getSystemInfoMd5():'',
            'ytuid': sess.getYtUuid()
        }
        if (params.repeatToken) {
            headParams.rtn = params.repeatToken
        }
        return httpCommon.getHTTP().post(query, params, {headers: headParams}).then((response) => {
            return this.doSuccess(response, callback, vm);
        }).catch((error) => {
            this.doError(error, vm);
        });
    },
    postForm(query, params, callback, vm) {
        this.showLoading(vm)
        let headParams = {
            'authorization': sess.get('accessToken'),
            'Content-Type': 'multipart/form-data',
            'sys': sess.getSystemInfoMd5()?sess.getSystemInfoMd5():'',
            'ytuid': sess.getYtUuid()
        }
        if (params.repeatToken) {
            headParams.rtn = params.repeatToken
        }
        return httpCommon.getHTTP().post(query, params, {
            timeout: 100000,
            headers: headParams
        }).then((response) => {
            return this.doSuccess(response, callback, vm);
        }).catch((error) => {
            this.doError(error, vm);
        });
    },
    postDownload(query, params, callback, vm) {
        this.showLoading(vm)
        let headParams = {
            'authorization': sess.get('accessToken'),
            'sys': sess.getSystemInfoMd5()?sess.getSystemInfoMd5():'',
            'ytuid': sess.getYtUuid()
        }
        if (params.repeatToken) {
            headParams.rtn = params.repeatToken
        }
        return httpCommon.getHTTP().post(query, params, {headers: headParams,responseType: 'blob'}).then((response) => {
            if (loading) {
                loading.close()
            }
            return callback(response)
        }).catch((error) => {
            this.doError(error, vm);
        });
    },
    put(query, params, callback, vm) {
        this.showLoading(vm)
        let headParams = {
            'authorization': sess.get('accessToken'),
            'sys': sess.getSystemInfoMd5()?sess.getSystemInfoMd5():'',
            'ytuid': sess.getYtUuid()
        }
        if (params.repeatToken) {
            headParams.rtn = params.repeatToken
        }
        return httpCommon.getHTTP().put(query, params, {headers: headParams}).then((response) => {
            return this.doSuccess(response, callback, vm);
        }).catch((error) => {
            this.doError(error, vm);
        });
    },
    putForm(query, params, callback, vm) {
        this.showLoading(vm)
        let headParams = {
            'authorization': sess.get('accessToken'),
            'Content-Type': 'multipart/form-data',
            'sys': sess.getSystemInfoMd5()?sess.getSystemInfoMd5():'',
            'ytuid': sess.getYtUuid()
        }
        if (params.repeatToken) {
            headParams.rtn = params.repeatToken
        }
        return httpCommon.getHTTP().put(query, params, {
            headers: headParams
        }).then((response) => {
            return this.doSuccess(response, callback, vm);
        }).catch((error) => {
            this.doError(error, vm);
        });
    },
    delete(query, callback, vm) {
        this.showLoading(vm)
        const headers = {
            'authorization': sess.get('accessToken'),
            'sys': sess.getSystemInfoMd5()?sess.getSystemInfoMd5():'',
            'ytuid': sess.getYtUuid()
        }
        return httpCommon.getHTTP().delete(query, {headers: headers}).then((response) => {
            return this.doSuccess(response, callback, vm);
        }).catch((error) => {
            this.doError(error, vm);
        });
    },
    showLoading(vm) {
        if (vm && !vm.hideLoading) {
            loading = vm.$loading({
                lock: true,
                text: '加载中'
            })
        }
    },
    showSuccessMessage(vm, msg) {
        if (vm) {
            vm.$message({
                message: msg,
                type: 'success'
            })
        }
    },
    showErrorMessage(vm, msg) {
        if (vm) {
            vm.$message({
                message: msg,
                type: 'error'
            })
        }
    },
    showMessage(vm, msg) {
        if (vm) {
            vm.$message({
                message: msg
            })
        }
    },
    doSuccess(response, callback, vm) {
        if (loading) {
            loading.close()
        }
        console.log(response.data)
        console.log(response.data.status)
        console.info('rrrrrr')
        //                  $toast.show(response.data);
        if (response.data.status !== 200) {
            this.showErrorMessage(vm, response.data.message)
            // $toast.show(response.data.bbErrorMsg);
            if (response.data.status === -1003) { // 未登录
                // setTimeout(() => {
                //     // $router.forward('/pwdLogin');
                //     // vm.$router.push({name: 'Login'})
                //     location.href = '/login'
                // }, 1000)
            } else if (response.data.status === -1001) { // 用户名或密码错误

            }
        } else {
            // vm.$toast('ok');
            return callback(response.data)
        }
    },
    doError(error, vm) {
        if (loading) {
            loading.close()
        }
        console.log(error.toJSON())
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
            if (error.response.status === 401) {
                this.showErrorMessage(vm, '登录失效,请重新登录')
                setTimeout(function () {
                    sess.logout()
                    // vm.$router.replace({name: 'qrLogin'})
                    location.href = process.env.VUE_APP_YSZS_URL
                }, 1000)
            } else if (error.response.status === 403) {
                this.showErrorMessage(vm, '请求错误,没有权限,请联系管理员(403)')
            } else if (error.response.status === 500) {
                this.showErrorMessage(vm, '服务器错误,请联系管理员(500)')
            } else if (error.response.status === 413) {
                this.showErrorMessage(vm, '文件大小不能超过5m')
            } else {
                this.showErrorMessage(vm, error.response.data.message || error.response.data.error)
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
            this.showErrorMessage(vm, error.message)
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
            this.showErrorMessage(vm, error.message)
        }
        console.log(error.config)
    }
};
