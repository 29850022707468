export default {
    surveyPropertyType: {
        singleSelect: 1,
        multiSelect: 2,
        text: 3,
        textarea: 4,
        image: 5,
        checkbox: 6,
        date: 7,
        file: 8,
        // subSurvey: 9
    },
    surveyPropertySingleDisplayType: {
        normal: 1,
        drop: 2,
        boolean: 3
    },
    surveyPropertyMultiDisplayType: {
        normal: 1,
        drop: 2
    },
    surveyPropertyInputDisplayType: {
        text: 1,
        number: 2,
        digit: 3,
        mobile: 4,
        email: 5
    },
    surveyPropertyDateDisplayType: {
        ymd: 1, //年月日
        ym: 2, //年月
        y: 3 //年月
    },
    surveyPropertyImageDisplayType: {
        default: 1,
        swiper: 2
    },
    sessKey: {
        currentSiteDomain:'curr-site-domain',
        currentSite: 'curr-site',
        ytUid: 'yt-uid'
    }
}