<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import sess from "@/common/sess";
import httpUtil from "@/common/httpUtil";
import urlConstant from "@/common/urlConstant";
    var userAgent = navigator.userAgent
    export default {
        name: 'app',
        created() {
            this.addTrace()
        },
        methods:{
            addTrace() {
                const params = {
                    'action': 'app_on_show',
                    'systemInfo': userAgent
                }
                const systemInfoMd5 = sess.cacheSystemInfoMd5(params.systemInfo)
                params.systemInfoMd5 = systemInfoMd5
                params.uuid = sess.getYtUuid()
                params.userId = sess.get('userId')
                params.siteDomain = sess.get('siteId')
                if (!params.siteDomain) {
                    params.siteDomain = 'unsaved'
                }
                httpUtil.post(urlConstant.support.addUserTracePost, params, ()=>{

                },this)
            }
        }
    }
</script>

<style>
    @import "assets/css/main.css";
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        /*margin-top: 60px;*/
    }
</style>
