import md5Util from './utils/md5Util.js'
import StringUtil from "@/common/utils/StringUtil";
import constant from "@/common/constant";
export default {
  get(name) {
    let value = sessionStorage.getItem(name);
    if (/^\{.*\}$/.test(value)) {
      value = JSON.parse(value);
    }
    return value;
  },
  set(name, value) {
    let v = value;
    if (typeof v === typeof {}) {
      v = JSON.stringify(v);
    }
    return sessionStorage.setItem(name, v);
  },
  remove(name) {
    return sessionStorage.removeItem(name);
  },
  login(responseData) {
    this.set('accessToken',responseData.data.tn);
    this.set('userId', responseData.data.userId);
    this.set('username', responseData.data.username);
    this.set('nickname', responseData.data.nickname);
    this.set('roles', responseData.data.roles);
    this.set('siteId', responseData.data.siteId);
  },
  logout() {
    this.remove('accessToken')
    this.remove('userId')
    this.remove('username')
    this.remove('roles')
    this.remove('siteId')
    this.remove('nickname')
  },
  setCurrentDoctor(doctor) {
    this.set('patient-manager-current-doctor', doctor)
  },
  getCurrentDoctor() {
    return this.get('patient-manager-current-doctor')
  },
  getYtUuid() {
    let ytUid = this.get(constant.sessKey.ytUid)
    if (!ytUid) {
      ytUid = StringUtil.randomString()
      this.set(constant.sessKey.ytUid, ytUid)
    }
    return ytUid
  },
  cacheSystemInfoMd5(jsonInfo) {
    const md5Map = {
      'systemInfo': jsonInfo,
      'uuid': this.getYtUuid()
    }
    const md5 = md5Util.md5(JSON.stringify(md5Map))
    this.set('system-info-md5', md5)
    return md5
  },
  getSystemInfoMd5() {
    return this.get('system-info-md5')
  }
};
