import httpUtil from "./httpUtil";
import urlConstant from "./urlConstant";
const install = (Vue) => {
    Vue.config.errorHandler = (err, vm, info) => {
        console.error(err)
        let route = ''
        if (vm && vm.$route)
            route = vm.$route.fullPath
        const params = {
            appName: 'nuohua-web',
            error: err.stack?err.stack:err.toString(),
            info: info,
            page: route
        }
        httpUtil.post(urlConstant.support.appErrorPost, params, ()=>{}, vm)
    }
}

export default install